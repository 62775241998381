import { format as formatDate } from "date-fns";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import slugify from "slugify";

import ClubAddress from "@/components/ClubAddress";
import HolidayHours from "@/components/HolidayHours";
import Accordion from "@/components/accordion";
import SecondaryHeadline from "@/components/headlines/club-detail/secondary";
import useBreakpoint from "@/hooks/useBreakpoint";
import useWindowScroll from "@/hooks/useWindowScroll";
import { groupHolidayHours } from "@/utils/helpers/holidays";
import Helpers from "@/utils/helpers/response_handlers";

import styles from "./ClubInfo.module.scss";

const CreateHoursAccordion = ({
  data,
  holidays,
  displayHolidays,
  accordionID,
  label,
  selectedAccordion,
  onSelectAccordion,
}) => {
  return (
    <Accordion
      accordionID={accordionID}
      label={label}
      selectAccordionFunc={onSelectAccordion}
      selectedAccordion={selectedAccordion}
    >
      <ul>
        {data.map((day) => {
          const nameFormatted =
            day.name.charAt(0).toUpperCase() + day.name.slice(1);

          if (day.isClosed) {
            return (
              <li alt={day.name} key={day.name} title={day.name}>
                <label>{nameFormatted}</label>
                <time>Closed</time>
              </li>
            );
          }

          return (
            <li alt={day.name} key={day.name} title={day.name}>
              <label>{nameFormatted}</label>
              <time>
                {day.hours.map((hour) => (
                  <React.Fragment key={`${hour.open}-${hour.close}`}>
                    {hour.open} &mdash; {hour.close}
                    <br />
                  </React.Fragment>
                ))}
              </time>
            </li>
          );
        })}
      </ul>
      {displayHolidays && holidays && (
        <div className={styles.holidayException}>
          {holidays &&
            holidays.map((group) => (
              <HolidayHours data={group} key={group?.name} />
            ))}
        </div>
      )}
    </Accordion>
  );
};

CreateHoursAccordion.propTypes = {
  accordionID: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  displayHolidays: PropTypes.bool,
  holidays: PropTypes.array,
  label: PropTypes.string.isRequired,
  onSelectAccordion: PropTypes.func.isRequired,
  selectedAccordion: PropTypes.string.isRequired,
};

const CreateAmenitiesAccordion = ({
  data,
  accordionID,
  label,
  selectedAccordion,
  onSelectAccordion,
  className,
}) => {
  return (
    <Accordion
      accordionID={accordionID}
      className={className}
      label={label}
      selectAccordionFunc={onSelectAccordion}
      selectedAccordion={selectedAccordion}
    >
      <ul>
        {data.map((amenity) => {
          return <li key={amenity.title}>{amenity.title}</li>;
        })}
      </ul>
    </Accordion>
  );
};

CreateAmenitiesAccordion.propTypes = {
  accordionID: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  onSelectAccordion: PropTypes.func.isRequired,
  selectedAccordion: PropTypes.string.isRequired,
};

const CreateSecondaryHoursAccordion = ({
  data,
  accordionID,
  label,
  selectedAccordion,
  onSelectAccordion,
}) => {
  return (
    <Accordion
      accordionID={accordionID}
      label={label}
      selectAccordionFunc={onSelectAccordion}
      selectedAccordion={selectedAccordion}
    >
      <ul>
        {data.map((day) => {
          const item = day.fields;
          let isClosed = item.storeOpen;
          let openString, closeString;

          if (!item.timeOpen || !item.timeClose) {
            isClosed = true;
          } else {
            try {
              const now = new Date();
              const open =
                formatDate(now, "yyyy-MM-dd") +
                "T" +
                (item?.timeOpen?.trim()?.length === 4 ? "0" : "") +
                item.timeOpen;
              const close =
                formatDate(now, "yyyy-MM-dd") +
                "T" +
                (item?.timeClose?.trim()?.length === 4 ? "0" : "") +
                item.timeClose;
              openString = formatDate(open, "h:mma");
              closeString = formatDate(close, "h:mma");
            } catch (e) {
              console.error(e);
            }
          }

          return (
            <li key={item.day}>
              <label>{item.day}</label>

              {openString && closeString && isClosed ? (
                <time>
                  {openString} &mdash; {closeString}
                </time>
              ) : (
                <span>closed</span>
              )}
            </li>
          );
        })}

        <span className={styles.note}>*Hours subject to change</span>
      </ul>
    </Accordion>
  );
};

CreateSecondaryHoursAccordion.propTypes = {
  accordionID: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  onSelectAccordion: PropTypes.func.isRequired,
  selectedAccordion: PropTypes.string.isRequired,
};

const ClubInfo = ({ facility, data }) => {
  const isDesktop = useBreakpoint();
  const clubInfoRef = useRef(null);
  const [clubInfoY, setClubInfoY] = useState(0);
  const [clubInfoOpacity, setClubInfoOpacity] = useState(isDesktop ? 0 : 1);
  const [selectedAccordion, setSelectedAccordion] = useState("club-hours");

  useEffect(() => {}, []);

  useWindowScroll(() => {
    if (clubInfoRef.current && isDesktop) {
      const clubInfoRect = clubInfoRef.current?.getBoundingClientRect();

      const quarterComponentHeight = clubInfoRect?.height / 4;

      let opacity = 1;
      let topMargin = 0;

      if (clubInfoRect?.top < quarterComponentHeight) {
        opacity = 1;
        topMargin = 0;
      } else if (clubInfoRect?.top > quarterComponentHeight * 2) {
        opacity = 0;
        topMargin = quarterComponentHeight;
      } else {
        opacity =
          Math.round(
            ((quarterComponentHeight * 2 - clubInfoRect?.top) /
              quarterComponentHeight) *
              10
          ) * 0.1;

        topMargin = quarterComponentHeight - quarterComponentHeight * opacity;
      }

      if (clubInfoOpacity !== opacity) {
        setClubInfoOpacity(opacity);
      }

      if (clubInfoY !== topMargin) {
        setClubInfoY(topMargin);
      }
    }
  });

  const isPresale = facility?.isPresale;

  const handleSelectAccordion = (accordionID) => {
    if (selectedAccordion === accordionID) {
      setSelectedAccordion(null);
    } else {
      setSelectedAccordion(accordionID);
    }
  };

  const comingSoonFloorPlanImage =
    data?.comingSoonFloorPlanImage?.fields.file.url;
  const comingSoonFloorPlanImageMobile =
    data?.comingSoonFloorPlanImageMobile?.fields.file.url;

  const sectionSlug = slugify(data?.subNavLabel).toLowerCase();

  const {
    contactInformation,
    serviceHours,
    kidsClubServiceHours,
    spaServiceHours,
    holidays,
  } = facility;

  const secondaryStoreHours = data?.refreshmentStore?.fields;
  const amenitiesArray = facility?.amenities || [];
  const clubGeneralManager = facility.managers.find(
    (manager) => manager.title === "General Manager"
  );
  const clubSchedule = Helpers.convertFacilityApiLocationHours(
    serviceHours,
    "h:mma"
  );
  const kidsClubSchdule = Helpers.convertFacilityApiLocationHours(
    kidsClubServiceHours,
    "h:mma"
  );
  const spaSchedule = Helpers.convertFacilityApiLocationHours(
    spaServiceHours,
    "h:mma"
  );

  const groupedHolidays = groupHolidayHours(holidays);

  return (
    <section
      className={`${styles.clubInfo} module container`}
      data-is="ClubInfo"
      id={sectionSlug}
      ref={clubInfoRef}
    >
      <div
        className={`${styles.inner} row justify-content-between no-gutters`}
        style={{
          opacity: clubInfoOpacity,
          transform: `translateY(${clubInfoY}px)`,
        }}
      >
        <div
          className={`${styles.clubDataContainer} col-sm-16 col-md-16 col-lg-4`}
        >
          <SecondaryHeadline text={`Club Info`} />
          {contactInformation && (
            <div className={styles.clubAddress}>
              <ClubAddress
                contactInformation={contactInformation}
                label="Club Address"
              />
            </div>
          )}
          {clubGeneralManager && (
            <div className={styles.clubManager}>
              {clubGeneralManager.title}
              <br />
              {clubGeneralManager.formattedName}
            </div>
          )}
        </div>

        {!isPresale ? (
          <>
            <div
              className={`${styles.accordionContainer} col-sm-16 col-md-7 col-lg-5`}
            >
              {clubSchedule && (
                <CreateHoursAccordion
                  accordionID="club-hours"
                  data={clubSchedule}
                  displayHolidays={true}
                  holidays={groupedHolidays}
                  label="Club Hours"
                  onSelectAccordion={handleSelectAccordion}
                  selectedAccordion={selectedAccordion}
                />
              )}

              {kidsClubSchdule && (
                <CreateHoursAccordion
                  accordionID="kids-club"
                  data={kidsClubSchdule}
                  holidays={groupedHolidays}
                  label="Kids Club Hours"
                  onSelectAccordion={handleSelectAccordion}
                  selectedAccordion={selectedAccordion}
                />
              )}

              {spaSchedule && (
                <CreateHoursAccordion
                  accordionID="spa-schedule"
                  data={spaSchedule}
                  holidays={groupedHolidays}
                  label="Spa Hours"
                  onSelectAccordion={handleSelectAccordion}
                  selectedAccordion={selectedAccordion}
                />
              )}

              {secondaryStoreHours && (
                <CreateSecondaryHoursAccordion
                  accordionID="secondary-store-schedule"
                  data={secondaryStoreHours.hours}
                  label={`${secondaryStoreHours.label} Hours`}
                  onSelectAccordion={handleSelectAccordion}
                  selectedAccordion={selectedAccordion}
                />
              )}

              {amenitiesArray.length > 0 && (
                <CreateAmenitiesAccordion
                  accordionID="club-amenities"
                  className={styles.amenityMobileAccordion}
                  data={amenitiesArray}
                  label="Amenities"
                  onSelectAccordion={handleSelectAccordion}
                  selectedAccordion={selectedAccordion}
                />
              )}
            </div>

            <div
              className={`${styles.amenitiesContainer} col-sm-16 col-md-7 col-lg-5`}
            >
              {amenitiesArray.length > 0 && (
                <div className={styles.amenities}>
                  <h3 className={styles.amenitiesLabel} id="amenitiesHeader">
                    Amenities
                  </h3>
                  <ul aria-labelledby="amenitiesHeader">
                    {amenitiesArray.map((amenity) => {
                      return <li key={amenity.title}>{amenity.title}</li>;
                    })}
                  </ul>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className={`${styles.floorPlanContainer} col-lg-11`}>
            <div className={styles.floorPlanContainerUpper}>
              <h3>
                Floor Plan <span>{data?.floorPlanSquareFeet} sq ft</span>
              </h3>
            </div>
            <div
              className={`${styles.floorPlanContainerLower} d-lg-inline-block d-md-none d-sm-none d-xs-none`}
            >
              <img alt="floor plan" src={comingSoonFloorPlanImage} />
            </div>
            <div
              className={`${styles.floorPlanContainerLower} d-xl-none d-lg-none d-md-inline-block`}
            >
              <img alt="floor plan" src={comingSoonFloorPlanImageMobile} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

ClubInfo.propTypes = {
  data: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
};

export default ClubInfo;
