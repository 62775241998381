import PropTypes from "prop-types";
import React from "react";

import styles from "./ClubAddress.module.scss";

const ClubAddress = ({
  clubType,
  contactInformation,
  isPresale,
  label,
  salesOfficeAddress,
}) => {
  const whiteAddress = clubType !== "special";
  const addressColor = whiteAddress ? styles.whiteAddress : styles.grayAddress;

  return (
    <address
      aria-label={label}
      className={`${styles.address} ${addressColor}`}
      itemScope
      itemType="https://schema.org/PostalAddress"
      translate="no"
    >
      {isPresale && <h3 className={styles.label}>{label}</h3>}

      {isPresale && salesOfficeAddress ? (
        <a
          className={styles.clubAddress}
          href={`http://maps.google.com/?q=${encodeURIComponent(salesOfficeAddress)}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {salesOfficeAddress}
        </a>
      ) : (
        <a
          className={styles.clubAddress}
          href={`http://maps.google.com/?q=${encodeURIComponent(`${contactInformation.address}${" "}${contactInformation.city},${contactInformation.state}${" "}${contactInformation.zip}`)}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span itemProp="streetAddress">{contactInformation.address}</span>
          <br />
          <span itemProp="addressLocality">
            {contactInformation.city}
          </span>,{" "}
          <span itemProp="addressRegion">{contactInformation.state}</span>{" "}
          <span itemProp="postalCode">{contactInformation.zip}</span>
        </a>
      )}

      {contactInformation.phoneNumber &&
        contactInformation.phoneNumber !== "(000) 000-0000" && (
          <a href={`tel:${contactInformation.phoneNumber}`}>
            {contactInformation.phoneNumber}
          </a>
        )}
    </address>
  );
};

ClubAddress.propTypes = {
  clubType: PropTypes.string,
  contactInformation: PropTypes.object.isRequired,
  isPresale: PropTypes.bool,
  label: PropTypes.string,
  salesOfficeAddress: PropTypes.string,
};

export default ClubAddress;
