import PropTypes from "prop-types";
import { useCallback, useEffect, useRef } from "react";

const useWindowScroll = (onScroll) => {
  const isScrollTickingRef = useRef(false);

  const scrollListener = useCallback(() => {
    if (!isScrollTickingRef.current) {
      window.requestAnimationFrame(() => {
        onScroll(window.scrollY);

        isScrollTickingRef.current = false;
      });

      isScrollTickingRef.current = true;
    }
  }, [onScroll]);

  useEffect(() => {
    scrollListener();
    document.addEventListener("scroll", scrollListener);

    return () => {
      document.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);
};

useWindowScroll.propTypes = {
  onScroll: PropTypes.func.isRequired,
};

export default useWindowScroll;
